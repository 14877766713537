import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { AddFilled, AddAlt } from '@carbon/icons-react';
import { Field, Form, Formik } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import ExtraItemTagCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ExtraItemTagCellRenderer/ExtraItemTagCellRenderer';
import { formatDataForExtraTagCellRenderer } from 'app/components/AdvancedGrid/GridHelper';
import { MemberField } from 'app/components/DataTray/UpsertSellerAssignmentView/UpsertSellerAssignmentView';
import SellerSelect from 'app/components/SellerSelect/SellerSelect';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { handleError } from 'app/graphql/handleError';
import { UPSERT_TERRITORY_OWNERS } from 'app/graphql/mutations/upsertTerritoryOwners';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { DeploymentModelPhase, GridFields } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SellerAssignmentCellEditor.module.pcss';
const b = block(style);

export type OwnerSelectItem = {
  index: number;
  member: MemberField;
  ownership: number | '';
  effectiveStartDate?: string;
  effectiveEndDate?: string;
};

interface SellerAssignmentCellEditorProps extends ICellRendererParams {
  // TODO TQP-11293 Split view and edit functionality in SellerAssignmentCellEditor
  editable: boolean;
}

const MAX_SELLER_COUNT = 10;

export const SellerAssignmentCellEditor: React.FC<SellerAssignmentCellEditorProps> = (props) => {
  const { data, api, editable } = props;
  const { territoryId, ruleId } = data;
  const { selectedTenant } = useScope();
  const { selectedQuotaComponentId } = useBattleCard();
  const deploymentModelPhase = usePhase();
  const [isMaxSellerAssignmentCountEnabled] = useTreatment(SplitFeatures.AMWQ_MAX_SELLERS);
  const showToast = useShowToast();

  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage;
  const territoryOwners = data.owners;
  const isParentRow = !!data?.territoryId;
  const initialOwners = data.owners?.map((owner, index) => {
    return {
      index,
      member: { key: `${owner.lastName}, ${owner.firstName}`, value: owner.memberId },
      ownership: owner.ownership,
      effectiveStartDate: owner.effectiveStartDate,
      effectiveEndDate: owner.effectiveEndDate,
      ownerId: owner.ownerId
    };
  });

  // TODO: multiple sellers can be added at one time
  const numSellers = 1;
  const [upsertTerritoryOwners] = useMutation(UPSERT_TERRITORY_OWNERS, {
    onCompleted() {
      const toastText = formatMessage('ASSIGNED_SELLERS', { count: numSellers, territory: territoryId });
      showToast(<ToastMessage title={formatMessage('SELLERS_ADDED')} message={toastText} />, 'success');
      api.refreshServerSideStore({ purge: false });
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(
        <ToastMessage
          title={formatMessage('FAILED_TO_ADD_SELLER')}
          message={formatMessage('FAILED_TO_ADD_SELLER_MESSAGE')}
        />,
        'danger'
      );
    }
  });

  // Is Feature enabled and max sellers reached
  const isMaxSellerAssignmentCountEnabledAndMaxSellers =
    isMaxSellerAssignmentCountEnabled && data.owners?.length >= MAX_SELLER_COUNT;

  const handleFormattingSellerInfo = (seller) => seller && `${seller.firstName} ${seller.lastName}`;
  const sellerAssignmentCellEditorProps = formatDataForExtraTagCellRenderer(
    props,
    GridFields.OWNERS,
    handleFormattingSellerInfo
  );

  return (
    <div className={b('cell')}>
      {!!data?.owners?.length && (
        <ExtraItemTagCellRenderer {...sellerAssignmentCellEditorProps} data-testid="extra-tag-item-cell-renderer" />
      )}
      {isParentRow && (
        <Formik
          initialValues={{
            owners: territoryOwners?.length
              ? initialOwners
              : ([
                  { index: 0, member: null, ownership: 100, effectiveEndDate: null, effectiveStartDate: null }
                ] as OwnerSelectItem[])
          }}
          onSubmit={null}
          enableReinitialize
        >
          <Form>
            <Field
              name="seller"
              showErrors={false}
              component={SellerSelect}
              disabled={editable}
              allowPreventOverflow={true}
              renderTarget={(onClick, menuOpen) =>
                getAddButton(onClick, menuOpen, isMaxSellerAssignmentCountEnabledAndMaxSellers, editable)
              }
              shouldDisableExistingSellers={false}
              onSelectItem={(item) => {
                const newOwner = {
                  memberId: item.selectedItem.value,
                  ownership: 0
                };
                const updatedOwners = territoryOwners?.length ? [...territoryOwners, newOwner] : [newOwner];

                upsertTerritoryOwners({
                  variables: {
                    tenantId: selectedTenant?.id,
                    territoryId: ruleId,
                    quotaComponentId: isTQM ? selectedQuotaComponentId : null,
                    owners: updatedOwners?.map((owner) => ({
                      ownerId: owner?.ownerId,
                      memberId: owner?.memberId,
                      ownership: owner?.ownership,
                      effectiveStartDate: owner?.effectiveStartDate,
                      effectiveEndDate: owner?.effectiveEndDate
                    }))
                  }
                });
              }}
              data-testid="seller-select"
            />
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default SellerAssignmentCellEditor;

export const getAddButton = (
  onClick: () => void,
  menuOpen: boolean,
  disabled: boolean,
  editable: boolean
): React.ReactNode => {
  const isDisabled = disabled || !editable;
  let content;

  if (!editable) {
    content = formatMessage('NO_EDIT_PERMISSION');
  } else if (disabled) {
    content = formatMessage('TOO_MANY_SELLERS', { maxSellers: MAX_SELLER_COUNT });
  } else {
    content = formatMessage('ADD_SELLER');
  }

  return (
    <MessageTooltip
      content={content}
      target={
        <IconButton
          type="button"
          icon={menuOpen ? <AddFilled size={20} /> : <AddAlt size={20} />}
          onClick={onClick}
          testId="add-seller-btn"
          disabled={isDisabled}
        />
      }
      placement={'top'}
    />
  );
};

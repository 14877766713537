import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Card, Classes, Menu, Spinner } from '@blueprintjs/core';
import { Archive, Copy, Edit, OverflowMenuVertical } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import ClampLines from 'react-clamp-lines';
import { Link, useHistory } from 'react-router-dom';

import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import ConfirmActionModal from 'app/components/ConfirmActionModal/ConfirmActionModal';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { PCJobType } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_PLANNING_CYCLE } from 'app/graphql/mutations/upsertPlanningCycle';
import { GET_PLANNING_CYCLES } from 'app/graphql/queries/getPlanningCycles';
import { GET_TENANT_WIDE_INFO } from 'app/graphql/queries/getTenantWideInfo';

import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import useShowToast from 'app/hooks/useShowToast';

import { DeploymentModelPhase, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import dummyThumbnail from 'assets/pngs/dashboard.png';

import CycleCloneDialog from './CycleCloneDialog/CycleCloneDialog';
import style from './CyclesCard.module.pcss';

const b = block(style);

export type CardProps = {
  date?: string;
  title?: string;
  image?: string;
  skeleton?: boolean;
  planningCycleId?: number;
  planningCycleSlug?: string;
  clonePlanningCycle?: (planningCycleId: number, planningCycleName: string) => void;
  isPlanningCycleActivated?: boolean;
};

const modifiers = {
  arrow: {
    enabled: false
  }
};

const CyclesCard: React.FC<CardProps> = ({
  title,
  date,
  skeleton,
  planningCycleId,
  planningCycleSlug,
  clonePlanningCycle,
  isPlanningCycleActivated
}: CardProps) => {
  const [showArchiveDialog, setShowArchiveDialog] = useState<boolean>(false);
  const [showCycleCloneDialog, setShowCycleCloneDialog] = useState<boolean>(false);
  const [isMultipleDeploymentModels, setIsMultipleDeploymentModels] = useState<boolean>(false);

  const showToast = useShowToast();
  const { userRole, pcJobsInProgress, userMembershipSpecs } = useUser();

  const { selectedTenant } = useScope();

  const history = useHistory();
  useEffect(() => {
    if (!userMembershipSpecs || !selectedTenant) {
      return;
    }

    const planningCycles = userMembershipSpecs.find((ms) => ms?.tenantId === selectedTenant.id)?.planningCycles;
    const deploymentModels = planningCycles?.find((pc) => pc.planningCycleId === planningCycleId)?.deploymentModels;

    if (!deploymentModels) {
      return;
    } else if (deploymentModels.length > 1) {
      setIsMultipleDeploymentModels(true);
    }
  }, [userMembershipSpecs, selectedTenant]);

  const [archivePlanningCycle, { loading: archivingLoading }] = useMutation(UPSERT_PLANNING_CYCLE, {
    onCompleted() {
      showToast(formatMessage('PLANNING_CYCLE_ARCHIVE_SUCCESS', { title }), 'success');
      setShowArchiveDialog(false);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('PLANNING_CYCLE_ARCHIVE_FAILURE', { title }), 'danger');
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_TENANT_WIDE_INFO, GET_PLANNING_CYCLES]
  });

  const makePlanningPath = useMakePlanningPath();

  //eslint-disable-next-line no-restricted-syntax
  const userIsAdmin = userRole === UserRoleType.ADMIN;

  const pcCloneJobInProgressForPC = !!pcJobsInProgress?.find(
    (pc) => pc.jobType === PCJobType.PLANNING_CYCLE_CLONE && pc.planningCycleId === planningCycleId
  );

  const pcStartDateChangeJobInProgressForPC = !!pcJobsInProgress?.find(
    (pc) => pc.jobType === PCJobType.START_DATE_CHANGE && pc.planningCycleId === planningCycleId
  );

  const menuContent = (
    <div className={b('menu__item')}>
      <Menu>
        {/* tried to apply href prop to it (according to bp docs) however altho the link was there the redirect wasn't happening */}
        {/* my suspicion is it might got to do something with outer Link, need to investigate */}
        <MenuItem
          icon={<Edit />}
          text={formatMessage('EDIT')}
          data-testid={'cycle-card-menu-edit-button'}
          onClick={() => history.push(`/${selectedTenant?.slug}${RoutePaths.EDIT_PLAN}/${planningCycleSlug}`)}
        />
        <MenuItem
          icon={<Copy />}
          disabled={pcCloneJobInProgressForPC}
          text={formatMessage('MAKE_A_COPY')}
          data-testid={'cycle-card-menu-copy-button'}
          onClick={() =>
            isMultipleDeploymentModels ? setShowCycleCloneDialog(true) : clonePlanningCycle(planningCycleId, title)
          }
        />
        <MenuItem
          icon={<Archive />}
          text={formatMessage('ARCHIVE')}
          data-testid={'cycle-card-menu-archive-button'}
          onClick={() => setShowArchiveDialog(true)}
        />
      </Menu>
    </div>
  );

  return (
    <>
      <Card className={b()} title={title}>
        {pcStartDateChangeJobInProgressForPC && (
          <div className={b('spinner')} data-testid="cycles-card-spinner">
            <Spinner intent="primary" size={40} />
          </div>
        )}
        <Link
          to={makePlanningPath({
            planningCycleSlug,
            deploymentModelSlug: isPlanningCycleActivated ? DeploymentModelPhase.manage : DeploymentModelPhase.plan
          })}
          className={b('link', { disabled: !planningCycleSlug })}
          data-testid="cycle-card"
        >
          <div className={b('header', { hideHeader: !userIsAdmin })}>
            <CanUser
              perform={UserAction.PLANNING_CYCLES_MODIFY}
              yes={
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Popover content={menuContent} placement={'bottom-end'} modifiers={modifiers}>
                    <div
                      className={skeleton ? style.CyclesCard_hide : style.CyclesCard_menu}
                      data-testid={'cycle-card-header-menu'}
                    >
                      <OverflowMenuVertical size={32} />
                    </div>
                  </Popover>
                </div>
              }
            />
          </div>
          <div className={skeleton ? `${Classes.SKELETON} ${b('title')}` : b('title')}>
            {!skeleton && (
              <HTMLHeading tagLevel="h2" styleLevel="h4">
                <ClampLines text={title} id="trunc-title" data-testid="trunc-title" lines={2} buttons={false} />
              </HTMLHeading>
            )}
          </div>
          <div className={skeleton ? `${Classes.SKELETON} ${b('date')}` : b('date')}>
            {date ? formatMessage('CREATED_WITH_DATE', { date }) : ''}
          </div>
          <div className={skeleton ? `${Classes.SKELETON} ${b('thumbnailContainer')}` : b('thumbnailContainer')}>
            <img className={b('thumbnail')} src={dummyThumbnail} alt="" />
          </div>
        </Link>
      </Card>
      <ConfirmActionModal
        isOpen={showArchiveDialog}
        isSubmitting={archivingLoading}
        actionButtonIntent={'danger'}
        onCancel={() => setShowArchiveDialog(false)}
        confirmButtonText={formatMessage('ARCHIVE')}
        onConfirmAction={async () => {
          await archivePlanningCycle({
            variables: {
              tenantId: selectedTenant?.id,
              planningCycleArchived: true,
              planningCycleId
            }
          });
        }}
        title={formatMessage('ARCHIVE_WITH_TITLE', { title })}
        body={
          <div>
            <div>{formatMessage('PLANNING_CYCLE_ARCHIVE_MESSAGE_1')}</div>
            <div>{title}</div>
            <br />
            <div>{formatMessage('PLANNING_CYCLE_ARCHIVE_MESSAGE_2')}</div>
          </div>
        }
      />
      <>
        {showCycleCloneDialog && (
          <CycleCloneDialog
            onSubmit={() => {
              clonePlanningCycle(planningCycleId, title);
              setShowCycleCloneDialog(false);
            }}
            onClose={() => {
              setShowCycleCloneDialog(false);
            }}
            isOpen={showCycleCloneDialog}
            planningCycleName={title}
          />
        )}
      </>
    </>
  );
};

export default CyclesCard;

import { useEffect, useMemo, useRef, useState } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import {
  GetCustomerAggregatePins,
  GetCustomerAggregatePinsVariables,
  TGTAggregationInput
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useGetCustomerAggregatePins } from 'app/graphql/queries/getCustomerAggregatePins';

import { AggregatePin } from 'app/models';

import { startPerformanceTimer } from 'utils/helpers/analyticsReporter';
import { CompressionUtil } from 'utils/helpers/CompressionUtil';
// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

import { CustomerPinsPaging, useCustomerPinsPaging } from './useCustomerPinsPaging';

export const useCustomerAggregatesFeatures = (
  measureId: number
): {
  customerPins: AggregatePin[];
  loading: boolean;
  refetchAllPins: () => void;
} => {
  const { selectedQuotaComponentId, selectedBattleCardId, selectedTerritoryGroupTypeId, accountSourceFilter } =
    useMapContextRedistributor();
  const [customerPins, setCustomerPins] = useState<AggregatePin[]>([]);

  const customerPinsPaging = useCustomerPinsPaging();

  const refetchAllPins = () => {
    setCustomerPins([]);
    fetchAllPinsAndSaveToState();
  };

  const input: TGTAggregationInput = useMemo(() => {
    if (!selectedBattleCardId || !selectedQuotaComponentId || !selectedTerritoryGroupTypeId || !measureId) return null;
    return {
      battlecardId: +selectedBattleCardId,
      quotaComponentId: selectedQuotaComponentId,
      territoryGroupId: selectedTerritoryGroupTypeId,
      measureId
    };
  }, [selectedBattleCardId, selectedQuotaComponentId, selectedTerritoryGroupTypeId, measureId]);

  const { loading, fetchMore, refetch } = useGetCustomerAggregatePins({
    fetchPolicy: 'network-only',
    skip: true,
    onError() {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('CUSTOMER_PINS_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    setCustomerPins([]);
  }, [input]);

  const canonicalRequestId = useRef(0);

  const fetchAllPinsAndSaveToState = () => {
    canonicalRequestId.current += 1;
    const localRequestId = canonicalRequestId.current;

    getAllPinPages(input, refetch, fetchMore, customerPinsPaging)
      .then((data) => {
        if (localRequestId !== canonicalRequestId.current) return;
        setCustomerPins(data);
      })
      .catch((error) => {
        if (localRequestId !== canonicalRequestId.current) return;
        handleError(null, error);
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('CUSTOMER_PINS_ERROR'), 'danger');
        setCustomerPins([]);
      });
  };

  useEffect(() => {
    if (!input) {
      return () => null;
    }
    fetchAllPinsAndSaveToState();
    return () => {
      canonicalRequestId.current += 1;
    };
  }, [input, accountSourceFilter]);

  return {
    customerPins,
    loading,
    refetchAllPins
  };
};

const getAllPinPages = async (
  input: TGTAggregationInput,
  getFirstPage: (variables?: Partial<GetCustomerAggregatePinsVariables>) => Promise<{ data: GetCustomerAggregatePins }>,
  getAdditionalPage: (input: {
    variables?: Partial<GetCustomerAggregatePinsVariables>;
  }) => Promise<{ data: GetCustomerAggregatePins }>,
  customerPinsPaging: CustomerPinsPaging
): Promise<AggregatePin[]> => {
  const timer = startPerformanceTimer('getAllPinPages');
  const { pageSize, isPagingEnabled } = customerPinsPaging;
  const paginationInput = isPagingEnabled
    ? {
        startRow: 1,
        endRow: pageSize
      }
    : null;
  const firstPage = (await getFirstPage({ input: { ...input, ...paginationInput }, includeCount: true })).data;

  const compressedFirstPage = firstPage.getTGTAggregate.pinsCompressed;
  const decompressedFirstPage = CompressionUtil.decompress(compressedFirstPage);

  const pagePromises: Promise<AggregatePin[]>[] = [Promise.resolve(decompressedFirstPage)];
  if (isPagingEnabled) {
    const totalRowCount = firstPage.getTGTAggregate.numberOfAccounts ?? 0;
    const pageCount = Math.ceil(totalRowCount / pageSize);

    for (let pageIndex = 1; pageIndex < pageCount; pageIndex++) {
      pagePromises.push(
        getAdditionalPage({
          variables: {
            input: {
              ...input,
              startRow: pageSize * pageIndex + 1,
              endRow: pageSize * (pageIndex + 1)
            },
            includeCount: false
          }
        }).then(({ data }) => CompressionUtil.decompress(data.getTGTAggregate.pinsCompressed))
      );
    }
  }

  const customerPinPages = await Promise.all(pagePromises);
  const allCustomerPins = customerPinPages.flat();
  timer.stop({
    count: allCustomerPins.length,
    pageCount: customerPinPages.length
  });
  return allCustomerPins;
};

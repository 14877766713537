import React, { Dispatch, SetStateAction, useRef } from 'react';

import {
  QueryLazyOptions,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery
} from '@apollo/client';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useScope } from 'app/contexts/scopeProvider';

import { CONSECUTIVE_POLLING_ERROR_LIMIT } from 'app/global/variables';

import { GetFileUploadProgress, GetFileUploadProgressVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_FILE_UPLOAD_PROGRESS } from 'app/graphql/queries/getFileUploadProgress';

import { FileStatus, FileUploadResult } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UseGetFileUploadProgressProps {
  fileUploadInProgress?: FileUploadResult[];
  setFileUploadInProgress?: Dispatch<SetStateAction<FileUploadResult[]>>;
}

interface UseGetFileUploadProgressReturn {
  pollForProcessingStatus: (options?: QueryLazyOptions<GetFileUploadProgressVariables>) => void;
  stopPolling: () => void;
}

export const useGetFileUploadProgress = ({
  fileUploadInProgress,
  setFileUploadInProgress
}: UseGetFileUploadProgressProps): UseGetFileUploadProgressReturn => {
  const { selectedTenant } = useScope();
  const metadataFileIds = fileUploadInProgress.reduce((acc, file) => {
    acc = [...acc, file.tableId];
    return acc;
  }, []);

  const filterFileUploadInProgress = (completedFile) => {
    return fileUploadInProgress.filter((file) => completedFile.fileId !== file.tableId);
  };

  const consecutiveErrorCount = useRef(0);

  const [pollForProcessingStatus, { stopPolling, ...rest }] = useLazyQuery<
    GetFileUploadProgress,
    GetFileUploadProgressVariables
  >(GET_FILE_UPLOAD_PROGRESS, {
    variables: {
      fileIds: metadataFileIds
    },
    fetchPolicy: 'network-only',
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true,
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);

      if (!selectedTenant || consecutiveErrorCount.current >= CONSECUTIVE_POLLING_ERROR_LIMIT) {
        return stopPolling();
      }
      consecutiveErrorCount.current += 1;
    },
    onCompleted(data) {
      const { getFileUploadProgress } = data;
      if (getFileUploadProgress?.length === 0) {
        consecutiveErrorCount.current = 0;
        stopPolling();
      }
      if (getFileUploadProgress) {
        // Check whether processing status has changed yet to completed or failed
        // status could be 'pending' and we let it poll until we find a
        // 'completed' or 'failed' status
        getFileUploadProgress?.forEach((completedFile) => {
          if (completedFile.status === FileStatus.COMPLETED) {
            setFileUploadInProgress(filterFileUploadInProgress(completedFile));
            // eslint-disable-next-line deprecation/deprecation
            showToast(formatMessage('FILE_HAS_BEEN_PUBLISH_SUCCESS', { name: completedFile.fileName }), 'success');
          }
          if (completedFile.status === FileStatus.FAILED) {
            setFileUploadInProgress(filterFileUploadInProgress(completedFile));
            // eslint-disable-next-line deprecation/deprecation
            showToast(
              <ToastMessage title={`Import ${completedFile.fileName} failed`} message={completedFile.message} />,
              'danger',
              'top',
              60000
            );
          }
        });
      }
    }
  });
  return { pollForProcessingStatus, stopPolling, ...rest };
};
